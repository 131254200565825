.animated-number {
    display: inline-block;
    transition: transform 0.5s ease-in-out;
    font-weight: bold; /* Make the numbers bold */
  }
  
  .spin {
    animation: spin 0.5s ease-in-out forwards;
  }
  
  @keyframes spin {
    0% {
      transform: rotateX(0);
    }
    50% {
      transform: rotateX(180deg);
    }
    100% {
      transform: rotateX(0);
    }
  }
  