.prediction {
    text-align: center;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #000000, #020738);
    height: fit-content;
    width: fit-content;
    min-width: 100%;
    min-height: 100vh;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  
  .prediction h3 {
    color: #bababa;
    margin-bottom: 20px;
    margin-top: 0px;
    font-size: 2.5rem; /* Increased size for emphasis */
  }
  
  .prediction table {
    margin: 0 auto;
    border-collapse: collapse;
    width: 90%;
    margin-top: 20px;
  }
  
  .prediction th, .prediction td {
    padding: 10px;
    border: 1px solid #4528c8;
    text-align: left;
  }
  
  .prediction th {
    background: linear-gradient(to bottom, black, #2b2b88);
    color: white; /* Ensure text is visible on the dark background */
  }

  .prediction table tr:nth-child(even) {
    background-color: #2f2f2f;
  }
  
  .prediction tr:hover {
    background-color: #2b2b88;
    cursor: pointer;
  }
  
  .prediction td {
    font-size: 16px;
    color: #bababa;
  }
  