.filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
}

.filtersBodyMain {
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-evenly;
    width: 80%;
}

.filtersMc {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 50%;
    height: 80px;
}

.filter-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.filter-item label {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.filter-item input {
    background-color: rgb(255, 255, 255);
    margin: 5px;
}

.filter-toggle-button {
    background-color: #2b2b88;
    cursor: pointer;
    margin-bottom: 10px;
    color: white;
    padding: 5px;
    border: 1px solid #4528c8;
    margin: 10px;
}

.filter-toggle-button:hover {
    background-color: #4528c8;
    border: 1px solid #ffffff;
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {

    .filters {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .filterslabel {
        display: flex;
        flex-direction: column;
        padding: 5px;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    
    .filtersBodyMain {
        display: flex;
        flex-direction: column;
        padding: 5px;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    
    .filtersMc {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    
    .filter-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .filter-item label {
        font-size: 14px;
        font-weight: 600;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
    
    .filter-item input {
        background-color: rgb(255, 255, 255);
        margin: 5px;
    }

}