.allTokensMain {
    margin-top: -20px;
}

.square {
    border: solid;
    border-color: #4528c8;
    width: 100px;
    padding: 10px;
    cursor: pointer;
    align-content: center;

    /* Add a shadow to simulate depth */
    box-shadow: 0 4px 6px rgba(34, 42, 195, 0.3), 0 1px 3px rgba(162, 149, 149, 0.742);
    
    /* Add smooth transition for hover effect */
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.square:hover {
    /* On hover, make it look like the square is lifting up */
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4), 0 2px 5px rgba(0, 0, 0, 0.3);
}

.square:active {
    /* On click, make it look like the square is being pressed down */
    transform: translateY(2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4), 0 1px 2px rgba(0, 0, 0, 0.3);
}


.squareHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.squareData {
    padding: 5px;
    font-size: 20px;
}

.squareDataTitle {
    padding: 5px;
    font-size: 22px;
}

@media (max-width: 1000px) {
    .allTokensMain {
        margin-top: 50px;
    }
}


@media (max-width: 600px) {
    .allTokensMain {
        margin-top: 50px;
    }

    .square {
        width: 50px;
        padding: 5px;
    }
    
    .squareHolder {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }
    
    .squareData {
        padding: 5px;
        font-size: 20px;
    }
    
    .squareDataTitle {
        padding: 5px;
        font-size: 10px;
    }

}