.App {
  text-align: center;
  background-color: black;
  color: white;
  min-height: 100vh; 
}


.notification {
  position: fixed; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 60px; /* Adjust this value based on the height of your header */
  left: 50%; /* Centers the notification horizontally */
  transform: translateX(-50%); /* Adjusts for centering */
  background-color: black; /* Background color */
  border: 2px solid #4528c8; /* Border color */
  color: white; /* Font color */
  padding: 10px 20px; /* Padding for the notification */
  z-index: 999; /* Ensure it overlays under the header */
  opacity: 0; /* Starts invisible for fade-in effect */
  transition: opacity 3s ease-in-out; /* Smooth fade-in and fade-out */
  width: calc(100% - 40px); /* Full width minus padding */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional shadow */
}

/* Fade in */
.notification.show {
  opacity: 1; /* Fully visible */
}

.notificationmain {
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center; /* Align items vertically in the center */
  margin: 10px 0; /* Optional: Add some vertical spacing */
}


.notificationPut {
  position: fixed; /* Fixes the notification to the top */
  top: 170px; /* Adjust this value based on the height of your header */
  left: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%); /* Adjusts for centering */
  background-color: black; /* Background color */
  border: 2px solid #4528c8; /* Border color */
  color: white; /* Font color */
  padding: 10px 20px; /* Padding for the notification */
  z-index: 999; /* Ensure it overlays under the header */
  opacity: 0; /* Starts invisible for fade-in effect */
  transition: opacity 3s ease-in-out; /* Smooth fade-in and fade-out */
  width: calc(100% - 40px); /* Full width minus padding */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Optional shadow */
}

/* Fade in */
.notificationPut.show {
  opacity: 1; /* Fully visible */
}

.messageText {
  font-size: 20px;
  font-weight: 700;
  margin-right: 10px;
}


/* Swipe to dismiss (can also use animation or transition) */
@keyframes swipe {
  from {
    transform: translateX(-50%) translateY(0);
  }
  to {
    transform: translateX(-50%) translateY(-100%); /* Move it up out of view */
  }
}


.App-link {
  color: #61dafb;
}

/* Header Styles */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #020114; /* Black background */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 1000; /* Ensure the header stays on top */
  box-shadow: none;
  border-bottom: 1px solid #fff;
}

.header-scrolled {
  background-color: rgb(1, 1, 65); /* Dark Blue with slight transparency */
  box-shadow: 0 2px 4px rgb(255, 255, 255); /* Darker shadow when scrolled */
}

.header-content {
  margin: 0; /* Remove default margin */
  padding: 10px; /* Reduce padding to 10px for closer spacing */
  display: flex; /* Keep elements in a row */
  align-items: center; /* Aligns logo and title vertically */
  justify-content: space-between;
}

.header h1 {
  margin: 0 0 0 10px; /* Adds some spacing between the logo and title */
  font-size: 24px; /* Font size for larger screens */
  color: #fff;
}

.header-left {
  display: flex;
  flex-direction: row;
  align-items: center; 
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

/* Ensure the main content starts below the header */
main {
  padding-top: 60px; /* Adjust based on header height */
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 10px;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(1) {
  transform: translateY(12px) rotate(45deg);
}

.hamburger.open .line:nth-child(3) {
  transform: translateY(-11px) rotate(-45deg);
}

.nav-menu {
  display: flex;
  gap: 20px;
}

.nav-menu a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;
}

.nav-menu a:hover {
  color: #ccc;
}

/* Adjusted Submenu Styles */
.submenu-container {
  position: relative;
}

.submenu-title {
  cursor: pointer;
  padding: 10px;
  display: inline-block;
  color: white; /* Match header text color */
  font-size: 18px; /* Adjust font size */
  text-decoration: none;
}

.submenu {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: #020114; /* Match header background */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for visibility */
  top: 100%; /* Position below the menu */
  left: 0;
  z-index: 1;
}

.submenu a {
  padding: 10px;
  text-decoration: none;
  color: white; /* Match header text color */
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submenu a:hover {
  background-color: #333; /* Subtle hover effect */
}

.submenu-container:hover .submenu {
  display: flex; /* Show submenu on hover */
}

.logout-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.login-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fauser {
  margin-top: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header-content {
    padding: 10px; /* Adjust padding for mobile */
  }

  .header h1 {
    font-size: 20px; /* Smaller font size for mobile */
  }

  .nav-menu {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #333;
    flex-direction: column;
    width: 200px;
    gap: 0;
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-menu a {
    padding: 15px;
    border-bottom: 1px solid #444;
  }

  .hamburger {
    display: flex;
  }

  .submenu {
    position: static;
    width: 100%;
    padding: 0;
    box-shadow: none;
  }

  .submenu a {
    padding: 15px;
    border-bottom: 1px solid #444;
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 18px; /* Further reduce font size for very small screens */
  }

  .header-content {
    padding: 5px; /* Less padding on very small screens */
  }
}
