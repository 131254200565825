.login-signup-container {
    width: 100%;
    overflow-x: auto;
    background: linear-gradient(to bottom, black, #2b2b88);
    color: white;
    min-height: 100vh;
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .loginmaintop {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 20px;
  }

  .loginmain {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    border: solid;
    width: fit-content;
    padding: 10px;
  }

  .signupinstructionmain {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    height: 500px;
    border: solid;
    width: fit-content;
    padding: 10px;
    width: 300px;
  } 

  .loginmain form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 300px;
  }

  .loginmain label {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 10px;
    width: 100%;
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .loginmain input {
    background-color: rgb(174, 179, 254);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 10px;
    width: 96%;
    height: 25px;
    font-size: 16px;
  }

  .login-signup-container button {
    background-color: rgb(55, 188, 240);
    font-size: 16px;
    color: white;
    height: 30px;
    cursor: pointer;
    margin-top: 20px;
    width: 99%;
  }

  .login-signup-container button:hover {
    background-color: rgb(255, 255, 255);
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: black;
  }

  .instructions1 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    flex-wrap: wrap;
    font-size: 14px;
  }

  .instructionsaddress {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .strong {
    color: rgb(55, 188, 240);
  }

  .copy {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .instructionsaddress2 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-bottom: 10px;
  }

  .paymentaddressbox {
    background-color: rgb(38, 58, 138);
    padding: 5px;
  }

  .tracked-tokens {
    margin-top: -10px;
    display: flex;
    flex-direction: row;
  }

  .accessList {
    border: solid;
    border-color:rgb(55, 188, 240);
    font-size: 12px;
    border-width: 1px;
    background-color: rgb(38, 58, 138);
    padding: 5px;
    text-wrap: wrap;
    text-align: center;
  }
  
  .copy-success {
    color: green; /* Set the text color to green to indicate success */
    font-size: 10px; /* Adjust the font size */
    background-color: #e6ffe6; /* Light green background for emphasis */
    padding: 8px; /* Add padding around the text */
    border: 1px solid green; /* Optional: Add a green border */
    border-radius: 5px; /* Rounded corners */
    display: inline-block; /* Ensure the message wraps properly */
  }
  
  .telegrambox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border: solid;
    border-color: white;
    padding: 10px;
    font-size: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  .tgicon {
    color: white;
    margin-left: 10px;
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 768px) {
    .loginmaintop {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      margin-top: 70px;
    }

    .loginmain input {
      width: 94%;
    }
  }