.contractScanMain {
  max-width: 100%;
  background-color: black;
  height: fit-content;
  padding: 20px;
  margin-top: 50px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
}

.container {
  margin-top: 50px;
  max-width: 100%;
  padding: 30px;
  background-color: #04022c;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Header styling */
h2 {
  margin-bottom: 30px;
  font-size: 2.5rem;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Input field styling */
form input {
  width: 100%;
  padding: 12px;
  margin: 15px 0;
  border-radius: 8px;
  background-color: #f4f4f4e6;;
  border: 2px solid #ddd;
  font-size: 1.1rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

form input:focus {
  border-color: #007bff;
  outline: none;
}

/* Button styling */
form button {
  padding: 12px 25px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

form button:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Error message styling */
.error {
  color: red;
  font-size: 1rem;
  margin-top: 10px;
  font-weight: 500;
}

/* Results container styling */
.results {
  margin-top: 30px;
  text-align: left;
}

/* Individual threat level styling */
.threat-level {
  margin-bottom: 25px;
  background-color: #f4f4f4e6;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  color: black;
}

.threat-level h4 {
  font-size: 1.3rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.threat-level h4 .fa {
  margin-left: 10px;
}

.threat-level ul {
  list-style-type: none;
  padding-left: 0;
}

.threat-level ul li {
  font-size: 1.1rem;
  color: #555;
  padding: 5px 0;
}

/* Raw Data section styling */
.results pre {
  background-color: #020114;
  color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  overflow: auto;
  font-size: 1rem;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Social links styling */
.social-links {
  font-size: 1rem;
  color: #333;
}

.social-links a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
  display: flex;
  flex-direction: row;

}

.social-links a:hover {
  color: #0056b3;
}

.tokennamecontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px;
  height: 10px;
}

.token-name {
  font-size: 2.5rem;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  
  /* 3D effect on text */
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3), -2px -2px 6px rgba(0, 0, 0, 0.3);
  
  /* 3D box effect with background */
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(145deg, #09022f, #070781, #09022f);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4), -4px -4px 10px rgba(255, 255, 255, 0.1);

  /* Optional 3D transform for extra effect */
  transform: perspective(1000px) rotateX(5deg) rotateY(5deg);
}

.social-links {
  display: flex;
  justify-content: space-around;  /* Centers items horizontally */
  align-items: center;      /* Centers items vertically */
  gap: 10px;    
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon-link {
  font-size: 50px;          /* Adjust icon size */
  color: #000;              /* Icon color */
  text-decoration: none;    /* Remove underline from link */
  display: inline-block;    /* Makes sure the link behaves like an inline element */
}


.social-link-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  transition: color 0.3s ease;
}

.social-link-item:hover {
  color: #007bff;
}

.social-icon {
  margin-right: 8px;
  font-size: 1.5rem;
}

.social-link-item {
  display: flex;
  align-items: center;
}

.holder-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.holder-address {
  margin-right: 15px;
}

.holder-percent {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.holder-percent .fa-exclamation-triangle {
  margin-left: 5px;
}



/* Responsive Design */
@media screen and (max-width: 768px) {
  .container {
    padding: 20px;
    margin: 20px;
  }

  h2 {
    font-size: 1.8rem; /* Reduced font size for smaller screens */
    margin-bottom: 20px;
  }

  form input,
  form button {
    font-size: 0.95rem; /* Slightly smaller font size */
  }

  form input {
    padding: 10px;
  }

  form button {
    padding: 10px 20px;
  }

  .results pre {
    padding: 15px;
    font-size: 0.9rem; /* Slightly smaller text for raw data */
  }

  .social-links a {
    font-size: 30px; /* Slightly smaller font for social links */
  }

  .tokennamecontainer {
    margin-top: 20px;
    padding: 50px;
    height: 30px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 15px;
    margin: 15px;
  }

  h2 {
    font-size: 1.5rem; /* Smaller font size for very small screens */
    margin-bottom: 15px;
  }

  form input,
  form button {
    font-size: 0.9rem; /* Smaller font size for mobile */
  }

  .threat-level h4 {
    font-size: 1.1rem; /* Slightly smaller font for threat levels */
  }

  .threat-level ul li {
    font-size: 0.9rem; /* Smaller font size for list items */
  }

  .results pre {
    padding: 12px;
    font-size: 0.85rem; /* Smaller font for raw data */
  }

  .social-links {
    font-size: 0.85rem; 
    margin-top: 10px;
  }

  .token-name {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .holder-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
  }
  
  .holder-address {
    font-size: 10px;
  }
  
  .holder-percent {
    font-size: 10px;
  }

  .tokennamecontainer {
    margin-top: 20px;
    padding: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 350px) {
  .holder-address {
    font-size: 8px;
  }
  
  .holder-percent {
    font-size: 10px;
  }

}