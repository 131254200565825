.home-container {
  padding: 10px;
  margin-top: 60px;
  height: 100%;
}

.home-container-pages {
  padding: 10px;
}

.moonshot-table-container {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.moonshot-table {
  width: 100%;
  border-collapse: collapse;
}

.moonshot-table th, .moonshot-table td {
  padding: 10px;
  border: 1px solid #4528c8;
  text-align: left;
}

.moonshot-table th {
  background: linear-gradient(to bottom, black, #2b2b88);
  color: white; /* Ensure text is visible on the dark background */
}



.moonshot-table tr:nth-child(even) {
  background-color: #2f2f2f;
}

.moonshot-table tr:hover {
  background-color: #2b2b88;
  cursor: pointer;
}

.moonshot-table th:hover {
  background: linear-gradient(to bottom, black, #6565d4);

}


.green-fade {
  animation: fadeInOutGreen 1s forwards;
}

.red-fade {
  animation: fadeInOutRed 1s forwards;
}

/* Existing styles ... */

/* Blurred row style */
.blurred-row {
  filter: blur(4px);
  background-color: #f0f0f0; /* Optional: change background to indicate it's blurred */
  cursor: pointer;
  position: relative;
}

/* Optional: Add an overlay to indicate restricted content */
.blurred-row::after {
  content: "Sign up to view details";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-weight: bold;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;
  border-radius: 4px;
}

/* Styles for clickable rows */
.clickable-row {
  position: relative;
}

/* Ensure the table container has relative positioning if using ::after */
.moonshot-table-container {
  position: relative;
}

/* Styles for SignupModal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #000000;
  border: 1px solid #4528c8;
  padding: 20px 30px;
  border-radius: 8px;
  position: relative;
  max-width: 400px;
  width: 90%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}



@keyframes fadeInOutGreen {
  0% {
    background-color: rgba(0, 255, 0, 0.5);
  }
  50% {
    background-color: rgba(0, 255, 0, 0.8);
  }
  100% {
    background-color: rgba(0, 255, 0, 0);
  }
}

@keyframes fadeInOutRed {
  0% {
    background-color: rgba(255, 0, 0, 0.5);
  }
  50% {
    background-color: rgba(255, 0, 0, 0.8);
  }
  100% {
    background-color: rgba(255, 0, 0, 0);
  }
}

/* Modal overlay to cover the entire screen */
.info-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Make sure it appears over everything */
}

/* Modal content box */
.info-modal-content {
  background-color: #000000;
  border: 1px solid #4528c8;
  padding: 20px 30px;
  border-radius: 8px;
  position: relative;
  max-width: 400px;
  width: 90%;
}

.info-modal-content h3 {
  margin-top: 0;
}

.info-modal-content button {
  margin-top: 15px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.info-modal-content button:hover {
  background-color: #0056b3;
}

.infomodalmain {
  display: flex;
  flex-direction: column;
}

.infomodalicons {
  text-align: left;
}


@media screen and (max-width: 768px) {
  .moonshot-table th, .moonshot-table td {
    font-size: 12px;
    padding: 6px;
  }

  .home-container {
    margin-top: 50px;
  }
}

@media (max-width: 600px) {
  /* Show the specific columns you want */
  .moonshot-table th:nth-child(1),
  .moonshot-table td:nth-child(1),
  .moonshot-table th:nth-child(2),
  .moonshot-table td:nth-child(2),
  .moonshot-table th:nth-child(6),
  .moonshot-table td:nth-child(6),
  .moonshot-table th:nth-child(10),
  .moonshot-table td:nth-child(10),
  .moonshot-table th:nth-child(11),
  .moonshot-table td:nth-child(11),
  .moonshot-table th:nth-child(12),
  .moonshot-table td:nth-child(12) {
    display: table-cell; /* Show these columns */
  }

  /* Hide all other columns */
  .moonshot-table th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(6)):not(:nth-child(10)):not(:nth-child(11)):not(:nth-child(12)),
  .moonshot-table td:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(6)):not(:nth-child(10)):not(:nth-child(11)):not(:nth-child(12)) {
    display: none; /* Hide other columns */
  }

  /* Set font size to 10px for header and data cells */
  .moonshot-table th, 
  .moonshot-table td {
    font-size: 7px;
    padding: 4px;
  }
}



