.allTokenDataMain {
    width: fit-content;
    background-color: black;
    height: fit-content;
    padding: 10px;
    margin-top: 50px;
}

.token-table {
    width: 100%;
    overflow-x: auto;
  }
  
  .token-table th, .token-table td {
    padding: 5px;
    border: 1px solid #4528c8;
    text-align: left;
    font-size: 10px;
  }
  
  .token-table th {
    background: linear-gradient(to bottom, black, #2b2b88);
    color: white; /* Ensure text is visible on the dark background */
  }
  
  .token-table tr:hover {
    background-color: #2b2b88;
    cursor: pointer;
  }
  
  .filters {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .filters div {
    margin-right: 20px;
  }
  