/* src/PerformanceSim.css */
.performance-sim {
    text-align: center;
    font-family: 'Arial', sans-serif;
    background: linear-gradient(135deg, #000000, #020738);
    height: fit-content;
    width: fit-content;
    min-width: 100%;
    min-height: 100vh;
    padding-bottom: 20px;
    padding-top: 20px;
}

.performance-sim h2 {
    color: #bababa;
    margin-bottom: 20px;
    margin-top: 0px;
    font-size: 2.5rem; /* Increased size for emphasis */
}

.performance-sim form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.performance-sim .form-group {
    margin-bottom: 20px;
    width: 80%;
    max-width: 400px; /* Limit form group width */
}

.form-group-labels {
    margin-bottom: 20px;
    width: 90%;
    display: flex;
    flex-wrap: wrap; /* Allow wrapping to the next line */
}

.label-item {
    flex: 10 10 10%; /* Take 25% of the row for each label (4 items per row) */
    display: flex;
    align-items: center; /* Center align the checkbox and label */
    margin-bottom: 10px; /* Add space between rows */
    padding: 15px; /* Optional: add padding around the items */
}

.performance-sim label {
    display: block;
    color: #bababa;
    font-weight: bold;
    margin-bottom: 8px;
}

.performance-sim input[type="number"] {
    padding: 10px;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
    width: 90%;
    box-shadow: inset 0 1px 3px rgb(7, 33, 101);
    transition: border 0.3s;
}

.performance-sim input[type="number"]:focus {
    border-color: #3498db;
    outline: none;
}

.performance-sim button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #3498db;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 20px;
}

.performance-sim button:hover {
    background-color: #2980b9;
}

.performance-sim table {
    margin: 0 auto;
    border-collapse: collapse;
    width: 80%;
    margin-top: 20px;
}

.performance-sim th, .performance-sim td {
    padding: 12px;
    border: 1px solid #4528c8;
    text-align: left;
}

.performance-sim th {
    background-color: #000000;
    color: #bababa;
}

.performance-sim td {
    font-size: 16px;
    color: #bababa;
}

.performance-sim .summary {
    background-color: #000000;
    border-radius: 5px;
    padding: 15px;
    margin: 20px;
    color: #bababa;
    border: 1px solid #4528c8;
}

.performance-sim .error {
    color: #e74c3c;
    font-weight: bold;
}

.performance-sim input[type="checkbox"] {
    margin-right: 10px;
}

.performance-sim label input[type="checkbox"] {
    transform: scale(1.2);
}

.performance-results {
    padding: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {

    .performance-sim table {
        max-width: 700px;
    }

}

/* Media Queries for Responsiveness */
@media (max-width: 600px) {

    .performance-sim table {
        max-width: 500px;
    }

    .performance-results {
        font-size: 12px;
    }
    .performance-sim th, .performance-sim td {
        font-size: 12px;
    }

}

/* Media Queries for Responsiveness */
@media (max-width: 420px) {

    .performance-sim table {
        max-width: 380px;
    }

    .performance-results {
        font-size: 10px;
    }
    .performance-sim th, .performance-sim td {
        font-size: 10px;
    }

}

/* Media Queries for Responsiveness */
@media (max-width: 380px) {

    .performance-sim table {
        max-width: 300px;
    }

    .performance-results {
        font-size: 8px;
    }
    .performance-sim th, .performance-sim td {
        font-size: 8px;
    }


    

}
