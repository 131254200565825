.trending-carousel {
    position: relative; /* Establish a positioning context for the logo */
    overflow: hidden; 
    margin-top: 50px;
  }
  
  .carousel-logo {
    position: absolute; /* Position the logo absolutely */
    right: 14px; /* Adjust this value as needed */
    top: 0px;
    bottom: 0px; /* Adjust this value as needed */
    width: 60px; /* Set the desired size */
    height: 60px; /* Set the desired size */
    border-radius: 50%; /* Make it circular */
    object-fit: cover; /* Ensure the image covers the area */
    z-index: 10; /* Ensure the logo appears above other elements */
  }

  .carousel-item {
    text-align: center;
    margin-top: 30px;
    height: 40px;
    margin-bottom: 8px;
  }
  
  .token {
    text-align: center;
    flex-direction: row;
    display: flex;
  }

  .tokenName {
    text-align: center;
    flex-direction: row;
    display: flex;
    font-weight: 700;
    padding: 4px;
    font-size: 16px;
    margin-left: 20px;
  }

  .percent {
    margin-left: 10px;
    background-color: rgb(0, 0, 162);
    border-radius: 50%; /* Ensures the shape is a circle */
    padding: 8px; /* Adjust padding for content inside */
    width: 20px; /* Set fixed width */
    height: 20px; /* Set fixed height */
    display: flex; /* Centers content horizontally and vertically */
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
  }
  
  
  .slick-dots li button:before {
    color: white;
  }

  .logoback {
    position: absolute; /* Position the logo absolutely */
    right: -10px; /* Adjust this value as needed */
    bottom: 0px; /* Adjust this value as needed */
    width: 60px; /* Set the desired size */
    height: 60px; /* Set the desired size */
    object-fit: cover; /* Ensure the image covers the area */
    z-index: 10; 
    background-color: black;
    border-radius: 50%; /* Make it circular */
  }

  .badMessage {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  